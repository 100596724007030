<template>
  <div class="auth-wrapper auth-v2">
    <b-row :style="loginStyle" class="auth-inner m-0 justify-content-center">
      <b-col lg="10" class="pt-5">
          <InitialHeader :onlyLogo="true" />
      </b-col>
      <b-col
          lg="12"
          class="d-flex align-items-center mb-5"
      >
        <b-col
            sm="12"
            md="10"
            lg="4"
            class="px-lg-3 mx-auto auth-bg py-5 rounded"
        >
          <h2
              class="mb-1 auth-title"
          >
            Defina sua senha
          </h2>

          <b-card-text class="mb-2">
            <feather-icon class="icons-color" icon="CheckCircleIcon" />
            Sua senha deve conter entre 8 e 16 caracteres.
          </b-card-text>

          <b-card-text class="mb-2">
            <feather-icon class="icons-color" icon="CheckCircleIcon" />
            Utilize ao menos duas das seguintes opções:

            <ul>
              <li>Letra Maiúscula</li>
              <li>Letra Minúscula</li>
              <li>Número</li>
              <li>Caractere Especial</li>
            </ul>
          </b-card-text>

          <b-card-text class="mb-2">
            <feather-icon class="icons-color" icon="SlashIcon" />
            Não utilize caracteres com acento.
          </b-card-text>

          <b-alert
              v-if="alert.show"
              show
              fade
              class="mb-2"
              :variant="alert.variant"
          >
            <div class="custom-alert">
              <span>
                <BIconExclamationCircle />
                {{ alert.message }}
              </span>
            </div>
          </b-alert>

          <!-- form -->
          <b-form
              class="auth-login-form mt-2"
              @submit.prevent="create"
          >
            <!-- password -->
            <b-form-group
                label="Senha *"
                label-for="password"
            >
              <b-form-input
                  id="password"
                  name="password"
                  type="password"
                  v-model="newRegister.password"
                  @focus="alert.show = false"
                  :class="formErrors.password ? 'border-danger' : ''"
                  placeholder="********"
                  autocomplete="off"
              />
              <small class="text-danger">{{ formErrors.password }}</small>
            </b-form-group>

            <!-- password confirmation -->
            <b-form-group
                label="Confirme a senha *"
                label-for="password"
            >
              <b-form-input
                  id="passwordConfirmation"
                  name="passwordConfirmation"
                  type="password"
                  v-model="newRegister.passwordConfirmation"
                  @focus="alert.show = false"
                  :class="formErrors.passwordConfirmation ? 'border-danger' : ''"
                  placeholder="********"
                  autocomplete="off"
              />
              <small class="text-danger">{{ formErrors.passwordConfirmation }}</small>
            </b-form-group>

            <!-- submit buttons -->
            <button :disabled="disabledButton" type="submit" class="btn button-form btn-block">
              {{ msgButton }}
            </button>
          </b-form>

          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login'}">
              <BIconChevronLeft />
              <span class="to-login">&nbsp;Volte ao Login</span>
            </b-link>
          </p>
        </b-col>
      </b-col>
      <FooterInitial/>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BIconExclamationCircle, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, BIconChevronLeft,
} from 'bootstrap-vue';
import { CheckCircleIcon, SlashIcon } from 'vue-feather-icons'
import { validation } from '@core/mixins/validation/validation'
import { messages } from "@core/mixins/validation/messages";
import LoginImage from "@/views/pages/authentication/components/LoginImage";
import FooterInitial from '@/views/components/custom/footer/FooterInitial.vue';
import InitialHeader from '@/views/components/custom/page-header/InitialHeader.vue';

export default {
  title: 'Nova senha',

  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BIconExclamationCircle,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    BIconChevronLeft,
    SlashIcon,
    CheckCircleIcon,
    LoginImage,
    FooterInitial,
    InitialHeader
},
  mixins: [validation, messages],

  mounted() {
    this.$helpers.indexablePage();
  },

  data() {
    return {
      newRegister: {
        password: '',
        passwordConfirmation: '',
      },
      loginStyle:{
          'background-image': `url(${require('@/assets/custom-images/bg/bg-login.png')})`,
          'background-repeat': 'no-repeat',
          'background-position': 'center center',
          'background-size': 'cover'
        },
      loadingShow: true,

      disabledButton: false,
      msgButton: 'Salvar',

      formErrors: {},

      alert: {
        show: false,
        message: '',
        variant: ''
      },
    }
  },

  methods: {
    async create() {
      if(this.validation()) {
        this.buttonLoading(true);
        this.buttonLoading(false);
      }
    },

    validation() {
      this.passwordValidate(this.newRegister.password);

      this.passwordConfirmation(this.newRegister.password, this.newRegister.passwordConfirmation);

      // Verifica se o formulário está preenchido ou não
      if(this.formValidationRun()) {
        return true;
      } else {
        this.formErrors = {};
        this.formErrors = this.formValidationErrors;

        return false;
      }
    },

    showAlert(message, variant) {
      this.alert.show = true;
      this.alert.message = message;
      this.alert.variant = variant;
    },

    buttonLoading(disabled) {
      if(disabled) {
        this.disabledButton = true;
        this.msgButton = 'Processando...';
      } else {
        this.disabledButton = false;
        this.msgButton = 'Entrar';
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>